@import "../Variables/color.scss";
@import "../Variables/size.scss";
@import "../Variables/variable.scss";
/*
==============CUSTOM BUTTON========
*/
.btn {
  font-family: $font-family-3;
  border-radius: 30px !important;
  outline: none;
  padding: 8px 30px !important;
  outline: 0;
  border: none !important;
}

/*
==============STYLED BUTTON========
*/

.btn-primary {
  font-size: $font-sm;
  background-color: $btn-color !important;
  color: $bg-color;
  letter-spacing: $l-s-2;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 400ms ease !important;
}

.btn-primary:hover {
  background-color: $Hover-color !important;
  margin-left: 2px;
  color: $bg-color !important;
}

.btn-primary:active {
  animation: btn-anim 300ms ease-out;
}

// Secondary button
.btn-custom {
  font-size: $font-sm;
  background-color: $Hover-color;
  color: $bg-color;
  letter-spacing: $l-s-2;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 400ms ease !important;
}

.btn-custom:hover{
  background-color: $Hover-color-100;
  color: $bg-color;
}

.btn-custom:active {
  animation: btn-anim 300ms ease-out;
}
// ANIMATION
@keyframes btn-anim {
  from {
    margin-top: 2px;
  }
  to {
    margin-top: 1px;
  }
}
