/*
===========Color Variable========
*/
$white : #ffffff;
$primary: #17082d;
$primary-100: #231835;
$primary-200: #0e031f;
$primary-300: #d0b5fa;
$primary-400: #e1ceff;

$Hover-color: #69b6d9;
$Hover-color-100: #2b83ac;


$bg-color: #ECF0F3;
$bg-color-100: #cfdce7;
$bg-color-200: #bbddf9;
$bg-color-300: #cce5f9;

$btn-color: #309267;
$btn-color-100: rgba(48, 146, 104, 0.884);
$btn-color-200: rgba(177, 242, 214, 0.884);
$btn-color-300: rgba(231, 248, 241, 0.884);

$muted-text: #6e7076;
$shadow-color: rgb(170, 168, 168);

$neo-color: #D1D9E6;
$neo-color-2: #d3dae1;
$neo-color-3: #dce1ea;
$danger : rgb(255, 1, 1); 

