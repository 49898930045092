@import '../../../../Scss/main.scss';



.book-list{
    .book-img-container{
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        transition: $transition;
        .book-img{
            width: 100%;
            height: 350px;
            overflow: hidden;
        }

        .inner-content{
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(2%, 40%);
            width: 100%;
            height: 100%;
            z-index: 33;
            opacity: 0;
            transition: $transition;
            overflow: auto;
            h3, p{
                color: $bg-color;
                font-family: $font-family-1;
                text-transform: uppercase;
                letter-spacing: $l-s-2;
            }
            h3{
                font-weight: bold;
            }
        }

        .inner-active{
            opacity: 1;
        }
        &::after{
            content: '';
            width: 100%;
            position: absolute;
            z-index: 30;
            height: 100%;
            background: $btn-color-100;
            top: 0;
            left: 0;
            transform: translateX(-100%);
            transition: $transition;
        }
    }

    .overlay-active::after{
        transform: translateX(0) !important;
    }


    /*
    =============MODAL CUSTOMIZATION================
    */
    .modal{
       background: rgba($primary, 0.7);
       display: none !important;
       transition: $transition !important;
       .modal-content{
           background: $btn-color;
           border: none ;
           border-radius: 0;

           .modal-header{
               border: none;
               padding: $s-1 $s-2;
               color: $bg-color;

               .btn-close{
                   color: $bg-color;
               } 
           }
       }
    }
     .show{
            display: block !important;
        }
}