@import "../../../../Scss/main.scss";

.academic {
  font-family: $font-family-1;

  .result_heading {
    text-transform: uppercase;
    font-weight: 700;
  }

  .table_box {
    max-height: 100vh;
    overflow: auto;
    border-radius: $border-radius-2;
    box-shadow: $dark-shadow;
    position: relative;

    .table {
      font-family: $font-family-2;

      thead {
        position: sticky; 
        top: 0;
        right: 0;
        tr {
          th {
            text-transform: uppercase;
            font-weight: 600;
            background-color: $btn-color;
            color: $white;
          }
        }
      }

      tbody {
        tr:nth-of-type(2n) {
          background-color: $primary-400;
        }
        tr:nth-of-type(2n + 1) {
          background-color: $bg-color-200;
        }
      }
    }
  }
}

/*
============== Search Bar ================
*/

.result_search_box {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  .searchInput {
    text-align: center;
    justify-content: cetern;
    justify-items: center;
    align-items: center;
    margin: auto;
    input {
      background-color: $neo-color;
      border: 1px solid $btn-color;
      border-radius: $border-radius-2;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      font-size: $s-1-q;
      padding: $s-1;
      height: $s-2;
      width: 18.75rem;
    }

    input:focus {
      outline: none;
    }

    .searchIcon {
      height: 33.5px;
      width: 50px;
      background-color: $neo-color;
      color: $btn-color;
      display: grid;
      place-items: center;
      font-size: $s-1-q;
      border: 1px solid $btn-color;
      border-left: 0;
    }

    #closeBtn{
      cursor: pointer;
    }
  }
}

