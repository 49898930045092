@import "../../../Scss/main.scss";

.footer-icon-holder {
  border-bottom: 1px solid $bg-color;

  .footer-icon {
    color: $bg-color;
    font-size: $font-sm;
    cursor:pointer;

    .footer-social-icon {
      font-size: $font-md;
    }
  }
}


.footer-title{
    font-size: $font-md;
    color: $bg-color;
    letter-spacing: $l-s-3;
}

.quick-link-menu{
    text-decoration: none;
    text-transform: capitalize !important;
    color: $bg-color;
    margin: 10px 0;
    transition: $transition;
}

 .quick-link-menu:hover{
    color: $Hover-color;
  }
