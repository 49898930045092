/*
==================BACKGROUND ================
*/
.bg-darkblue {
  background: $primary;
}
.bg-darkblue-2 {
  background: $primary-200;
}
.bg-btn {
  background-color: $btn-color !important;
}

.text-btn {
  color: $btn-color;
}

.text-light {
  color: $bg-color !important;
}

/*
==================TEXT================
*/

.text-title {
  font-size: $font-title;
  font-weight: bold;
}

// .text-title-sm {
//   font-size: $font-md;
//   font-weight: bold;
// }

.text-sub-title {
  font-weight: bold;
  color: $btn-color;
  font-family: $font-family-1;
  font-size: $font-big;
  text-transform: uppercase;
}

.text-content {
  font-size: $font-md;
  font-weight: 400;
}

.text-bold {
  font-weight: bold;
}

.ls-3 {
  letter-spacing: $l-s-3;
}

.ls-2 {
  letter-spacing: $l-s-2;
}
/*
==================BAR================
*/

.vertical-bar {
  height: 45px;
  width: 1px;
  background: #fff;
  border-radius: 2px;
}

.horizontal-bar {
  height: 0.8px;
  width: 45px;
  background: #fff;
  border-radius: 2px;
}


/*
==================ERROR CODE================
*/
.error{ 
  font-size: $font-big; 
  color: $danger;
  text-transform: uppercase; 
  font-weight: 700;
}

/*
==================ANIMATION================
*/

.anim-left {
  transition: all 100ms ease;
  animation: left-transition 1.5s ease-in-out;
}

.anim-right {
  animation: right-transition 1.5s ease-in-out;
}

.anim-opacity {
  animation: opacity-anim 1s ease-in-out;
}

.shadow {
  box-shadow: $light-shadow;
}

.shadow-dark {
  box-shadow: $dark-shadow;
}

@media screen and (max-width: 576px) {
  .text-title {
    font-size: $font-title-s; 
  }

  .text-title-sm {
    font-size: $font-md-s;
  }

  .text-sub-title{
    font-size: $font-big-s;
  }

  .text-content {
    font-size: $font-md-s;
    font-weight: 300;
  }
}
