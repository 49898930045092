@import "../../../Scss/main.scss";

/*
==================HERO SECTION======================
*/
.contact {
    overflow: hidden;
    .horizontal-bar,
    .vertical-bar{
        background: $muted-text;
    }
  .contact-cname {
    text-transform: uppercase;
    line-height: $s-5;
    letter-spacing: $l-s-2;
  }


  /*
==================CONTACT INFO SECTION======================
*/
  .contact-details{
    background-color: $primary;
    padding: $s-4 $s-2;
    height: 100%;
    width: 100%;
    border-bottom: 1px dotted $btn-color;

    .details{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .details-title {
            color: $Hover-color;
            letter-spacing: $l-s-3;
            text-transform: uppercase;
            font-weight: bold;
        }

        .icon-holder{
            color: $btn-color;
            font-size: $font-md;
            margin: $s-1 0;
        }

        .details-text{
            color: $bg-color;
            margin-bottom: $s-70;

            strong{
                font-family: $font-family-2;
                font-weight: 400;
            }
        }
    }
  }

  .contact-form{
      font-size: $font-normal;
      font-style: italic;

      .contact-input{
          background: $bg-color;
          border: 1px solid $muted-text;
          padding: $s-20 $s-1;
          
      }

      .contact-submit-btn{
          background: $primary;
          padding: $s-70 $s-1-h;
          transition: $transition;
          color: $bg-color;
          font-size: $font-sm;
          letter-spacing: $l-s-2;
          font-family: $font-family-3;
          border: 0;
          margin: $s-1 0;
      }

      .contact-submit-btn:hover{
        background: $Hover-color;
        margin-left: $s-10;
      }

  }
}


/*
==================CONTACT INFO SECTION======================
*/
