@import "../../../Scss/main.scss";

.about-us {
  overflow: hidden;
  .teachers-comment {
    background-color: $primary;
    color: $bg-color;
    height: 100%;
    padding: $s-2 $s-2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      font-family: $font-family-1;
      line-height: $s-2;
      letter-spacing: $l-s-1;
    }

    .teachers-name {
      display: flex;
      color: $btn-color;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      font-style: italic;
      font-weight: bold;

      .horizontal-bar {
        background: $btn-color;
      }
    }
  }
  .aceivement-slider {
    .aceive-img-holder {
      padding: 0 $s-70;
      position: relative;
      .aceive-img {
        height: 500px;
        width: 100%;
      }
    }

    .slick-center {
      img {
        box-shadow: $dark-shadow;
      }
    }
  }
}

/*
==================SLider info=========================
*/

.slick-prev::before,
.slick-next::before {
  line-height: 1;
  opacity: 0.75;
   content: "";
  border: solid $btn-color;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  width: $s-1;
  height: $s-1;
}

.slick-prev {
  left: -40px;
}
.slick-prev::before {
    transform: rotate(135deg);

}

.slick-next::before{
    transform: rotate(-45deg);
}
