@font-face {
  font-family: "Cormorant Garamond", serif;
  src: url(.././assets/Fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf)
      format("truetype"),
    url(.././assets/Fonts/Cormorant_Garamond/CormorantGaramond-BoldItalic.ttf)
      format("truetype"),
    url(.././assets/Fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf)
      format("truetype");
}

@font-face {
  font-family: "Crimson Text", serif;
  src: url(.././assets/Fonts/Crimson_Text/CrimsonText-Regular.ttf)
      format("truetype"),
    url(.././assets/Fonts/Crimson_Text/CrimsonText-Bold.ttf)
      format("truetype"),
    url(.././assets/Fonts/Crimson_Text/CrimsonText-SemiBold.ttf)
      format("truetype");
}

@font-face{
     font-family: "Open Sans", sans-serif;
  src: url(.././assets/Fonts/Open_Sans/OpenSans-Regular.ttf)
      format("truetype"),
    url(.././assets/Fonts/Open_Sans/OpenSans-Bold.ttf)
      format("truetype"),
      url(.././assets/Fonts/Open_Sans/OpenSans-SemiBold.ttf)
      format("truetype");
}