@import '../../../Scss/main.scss';

.course{
    .horizontal-bar{
        background: $btn-color !important;
    }

    .course-nb{
        font-family: $font-family-1;
        font-style: italic;
        font-weight: bold;
        text-align: center;
    }

    p{
        font-weight: 500;
    }
}