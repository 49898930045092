/*
============= ALL SIZE IN REM===============
*/

/*
============= FONT SIZE IN REM===============
*/
$font-normal: 1.2rem;
$font-md: 1.5rem;
$font-big: 2rem;
$font-title: 3rem;
$font-sm: 0.8rem;
$font-xl: 3.5rem;
$font-xxl: 5rem;

$font-normal-s: 1rem;
$font-md-s: 1.3rem;
$font-big-s: 1.7rem;
$font-title-s: 2.5rem;
$font-sm-s: 0.6rem;
$font-xl-s: 3rem;
$font-xxl-s: 4rem;

/*
============= LETTER SIZE IN REM===============
*/
$l-s-1: 1px;
$l-s-2: 0.12rem;
$l-s-3: 0.255rem;
$l-s-4: 0.5 rem;

/*
============= GENERAL IN REM===============
*/
$s-10: .1rem;
$s-20: .2rem;
$s-30: .3rem;
$s-40: .4rem;
$s-50: .5rem;
$s-60: .6rem;
$s-70: .7rem;
$s-1: 1rem;
$s-1-q: 1.25rem;
$s-1-h: 1.5rem;
$s-2: 2rem;
$s-3: 2rem;
$s-4: 3rem;
$s-5: 5rem;
$s-6: 6rem;
/*
============= EXTRA===============
*/
$light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
$dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
$max-width: 1170px;
$fixed-width: 620px;
$border-radius-1: 0.5rem;
$border-radius-2: 0.2rem;
$border-radius-btn: 1rem;
