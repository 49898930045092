@import "../../../Scss/Variables/size.scss";
@import "../../../Scss/Variables/variable.scss";
@import "../../../Scss/Variables/color.scss";

/*
===============CAROUSEL SLIDERR SECTION================
*/

.carousel-firstSlide {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .first-slide-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    justify-items: flex-start;
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: $bg-color;
  text-align: center;
  top: 30%;

  .first-slide-title {
    font-size: $font-xl;
    letter-spacing: $l-s-3;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }

  .carousel-caption-text{
    padding-top: $s-5;
  }

  p {
    text-align: left;
  }

  .carousel-bar {
    width: 8px;
    min-height: 20vh;
    background-color: $bg-color;
    border-radius: $border-radius-1;
    margin-right: 10px;
  }
}
.carousel-overlay {
  background-color: rgba(0, 0, 0);
  .carousel-img {
    position: relative;
    opacity: 0.3;
    height: 75vh !important;
  }
}

/*
===============COUNTER SECTION================
*/
.conter-icon {
  font-size: $font-md;
}

/*
===============RULE SECTION================
*/

.rule-conten {
  margin-left: 10%;
}

/*
===============RULE SECTION================
*/
.course-section {
  .card {
    border: none !important;
    background-color: $bg-color !important;
    transition: all 300ms ease;
    box-shadow: 18px 18px 30px $neo-color, -18px -18px 30px #ffffff;
    border-radius: 0 !important;
    animation: home-anim 800ms ease-in-out;
    min-height: 100%;

    .card-header {
      border-bottom: 1px solid $muted-text;
      background-color: transparent !important;

      h3 {
        font-weight: bold;
        color: $btn-color;
      }
    }

    .course-card-btn {
      color: $btn-color;
      text-decoration: none;
      text-transform: capitalize;
      font-family: $font-family-3;
      cursor: pointer;
      transition: all 400ms ease-in-out;
      font-size: $font-sm;
      letter-spacing: 3px;
    }

    .course-card-btn:hover {
      color: $bg-color;
      margin-left: 2px;
      background: $primary;
      padding: 10px 15px;
    }
  }

  .card:hover {
    padding-top: 2px;
  }
}

/*
===============RULE SECTION================
*/
.author-section {
  .card {
    background: transparent !important;
    border: none;

    .card-img-top {
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }
    .card-title {
      font-weight: bold;
    }

    .author-info {
      font-weight: bold;

      h4 {
        font-size: $font-md;
        font-style: italic;
      }
      p {
        text-transform: uppercase;
      }

      .phone {
        text-transform: lowercase;
      }
    }

    .icon-holder {
      a {
        font-size: $font-big;
        color: #309267;
        text-decoration: underline;
        margin-right: 30px;
        transition: all 500ms ease;
      }

      a:hover {
        color: $primary;
        transform: rotate(360deg);
      }
    }
  }
}

/*
======================TESTIMONIAL SECTION======================
*/
.review {
  min-height: 150px;
  overflow: hidden;

  .testimonial-sign {
    color: $bg-color-100;
    margin: 0 1rem 0 1rem;
  }

  .testimonial-name {
    font-weight: bold;
    text-transform: uppercase;
  }
  .testimonial-img {
    width: 100px;
  }
  .slick-dots {
    bottom: 5% !important;
    display: inline;

    li button::before {
      opacity: 0.15 !important;
      color: $primary-100 !important;
    }
  }
}

.slick-dots li.slick-active button::before {
  opacity: 1 !important;
  color: $btn-color !important;
}


@media screen and (max-width: 576px) {
  .first-slide-title {
    font-size: $font-sm;
  }
}
