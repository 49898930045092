@import "../../../Scss/main.scss";

.navbar-active {
  background-color: $primary !important;
  transition: $transition;
  box-shadow: $dark-shadow;
  border-bottom: 2px solid $btn-color;
}

.cnavbar {
  background-color: transparent;
  height: 90px;
  display: flex;
  align-items: center;
  justify-items: center;
  transition: $transition;

  .logo-holder {
    .logo {
      width: 70px;
    }
  }

  .nav-menu-active {
    color: $Hover-color !important;
  }

  .menu-holder {
    width: 80%;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;

    .nav-list {
      transition: $transition;
      list-style: none;

      .nav-menu {
        text-decoration: none;
        font-size: $font-sm;
        color: $bg-color;
        font-weight: normal;
        color: $bg-color;
        transition: $transition;
      }
    }

    .nav-list:hover {
      .nav-menu {
        color: $Hover-color;
        border-bottom: 1px solid $Hover-color;
      }
    }

    .nav-list:last-child:hover {
      border-bottom: none;
    }
  }

  .mobile-menu-icon {
    display: none;
  }
}
.mobile-submenu {
  display: none;
}

.submenu-icon{
  transition: $transition;
}
.submenu-item:hover{
  a,.submenu-icon{
    color: $Hover-color !important;
  }

  
}

@media screen and (max-width: 992px) {
  /* For mobile and tab*/
  .cnavbar {
    height: 90px;
    padding: 5px 0;
    .menu-mobile {
      display: block;
      justify-content: center;
      align-items: center;
    }

    .menu {
      display: none;
    }

    .menu-active {
      right: 0 !important;
    }

    .show-menu {
      position: absolute;
      top: 0;
      right: -100%;
      height: 100vh;
      width: 100%;
      background-color: $bg-color-100;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 0 10%;
      // animation: right-transition 600ms ease-in;
      transition: $transition;
      overflow: hidden;

      .nav-list {
        list-style: none;
        margin: 20px 0;
        transition: $transition;
        display: block;
        width: 100vw;
        text-align: right;

        .mobile-submenu {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          margin: 5px 30px;
          

          .mobile-submenu-link {
            color: $primary;
            font-size: $font-sm;
            text-decoration: none;
            padding: 5px 0;
            transition: $transition;
            letter-spacing: $l-s-2;

            .mobile-submenu-icon{
              margin-left: 10px;
            }
            
          }

          .mobile-submenu-link:hover,
          .mobile-submenu-icon:hover{
            color: $Hover-color;
          }
        }

        .nav-menu {
          text-decoration: none;
          color: $primary;
          letter-spacing: $l-s-3;
          font-size: $font-sm;
          font-weight: 400;
          transition: $transition;
        }
      }
      .nav-list:hover:not(.nav-list .nav-menu-active) {
        background: $bg-color;
        padding: 2% 0;
        display: block;
        box-shadow: $light-shadow;
        border-radius: $border-radius-1;
        .nav-menu {
          color: $Hover-color;
          margin-right: 10px;
        }
      }
    }

    .mobile-menu-icon {
      display: flex;
      align-items: flex-end;
      color: $bg-color;
      position: absolute;
      top: 30%;
      right: 10%;
      cursor: pointer;
      z-index: 1000;

      button {
        color: $bg-color;
        background: $bg-color;
        width: 20px;
        height: 20px;
      }
    }
  }
}
.submenu {
  position: absolute;
  background-color: $btn-color;
  color: $bg-color;
  padding: 15px;
  transition: $transition;
  font-size: $font-sm;
  font-weight: 400;
  letter-spacing: $l-s-2;
  display: none;
  a {
    text-decoration: none;
    color: $bg-color;
    transition: $transition;
  }

  a:hover {
    color: $primary-200;
  }
}

.submenu::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0px;
  width: 0px;
  border-bottom: 10px solid $btn-color;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.submenu-show {
  display: block;
}
