@import "../../Scss/main.scss";

.header-container {
  .hero-background {
    background-image: url(../../assets/img/extra_img/hero-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .hero-title {
      color: $bg-color;
      z-index: 3;
      font-style: italic;
    }

    .hero-subtitle {
      color: $bg-color;
      font-family: $font-family-1;
      z-index: 3;
      margin-top: 10px;
    }
  }

  .hero-background::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: $primary-100;
    opacity: 0.8;
    z-index: 2;
  }
}
