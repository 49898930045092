
@import '../Variables/variable.scss';

.font-crimson{
    font-family: $font-family-2;
}

.font-cormogrant{
    font-family: $font-family-1;
}

.font-open{
    font-family: $font-family-3;
}