@import "./Variables/variable.scss";
@import "./Variables/color.scss";
@import "./Bootstrap/bootstrap.scss";
@import "./button/button.scss";
@import "./Variables/size.scss";
@import "./Typography/typography.scss";
@import './Reuse/reuse.scss';
@import './Animation/anim.scss';
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: $bg-color !important;
}
