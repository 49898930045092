

/*
===============TRANSITION ANIMATION SECTION================
*/
$transition: all 500ms ease-in-out;
/*
===============ANIMATION SECTION================
*/

@keyframes left-transition{
    from {
        opacity: 0;
        transform: translateX(-120%);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes right-transition{
    from {
     transform: translateX(120%);
    }
    to{
        transform: translateX(0);
    }
}

@keyframes opacity-anim{
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}