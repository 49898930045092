@import "../../../Scss/main.scss";

/*
  =====================NOTICE SECTION=====================
  */

  .notice-box {
    padding: 30px;
    box-shadow: inset 10px 10px 20px $neo-color-2, inset -10px -10px 20px $white;
    background-color: $bg-color !important;
    border-radius: $border-radius-1;
    height: 450px;
    box-sizing: border-box;
    overflow: none;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .notice-board{
      width: 100%;
       .notice-error{
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: $l-s-2;
      text-align: center;
    }
    }
  }

  .notice-nav {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    justify-items: center;
    padding-left: 0;
    position: absolute;
    top: 5%;
    left: 0;
    width: 100%;

    .notice-nav-link {
      background-color: $bg-color;
      list-style: none;
      margin-left: 20px;
      color: $btn-color;
      text-transform: uppercase;
      padding: 5px 15px;
      
      font-size: $font-sm;
      font-weight: 500;
      letter-spacing: $l-s-2;
      border-radius: $border-radius-btn;
      transition: $transition;
      cursor: pointer;
      box-shadow: 10px 10px 18px $neo-color-3, -10px -10px 18px $white;
    }

     .notice-nav-link:first-child{
          margin-left: 0;
      }

    .notice-nav-link:hover {
      background-color: $btn-color;
      color: $bg-color;
    }

    .active-notice-nav {
      background: $btn-color;
      color: $bg-color;
    }
  }
  /*
  =====================NOTICE CONTENT SECTION
  */

 

 .all-notice {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: $s-1;

    .single-notice {
      list-style: none;
      padding: 20px 0;
      font-family: $font-family-3;
      letter-spacing: $l-s-1;
      font-size: $font-normal;
      text-decoration: none;
    }
  }

/*
=======================Single notice section =============
*/
.notice-img{
  width: 250px;
}

.notice-desctiption{
  font-weight: bold;
  background: $Hover-color;
  padding: 10px 15px;
  border-radius: $border-radius-1;
  color: $bg-color;
  margin-bottom: 25px;
  letter-spacing: $l-s-2;
}
.notice-download{
  background: $btn-color;
  color: $bg-color;
  padding: 10px 15px;
  font-family: $font-family-3;
  font-weight: 400;
  letter-spacing: $l-s-2;
  font-size: $font-sm;
  text-transform: uppercase;
}

.notice-link{
    background: none;
    font-size: $font-sm;
    text-decoration: underline;
    color: $Hover-color; 
    cursor: pointer;
    margin-left: $s-1;
  }

@media screen and (max-width: 576px) {
    .notice-box{
        padding: 30px 10px;
    }
    .notice-nav {
      .notice-nav-link {
        font-size: $font-sm-s;
        margin-left: 10px;
      }
      .notice-nav-link:first-child{
          margin-left: 0;
      }
    }

    .all-notice{
        .single-notice{
            font-size: $font-normal-s;
        }
    }
}
